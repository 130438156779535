<template  lang="pug">
	v-container(class="section-version" fluid)
		v-row(justify="center" align="center")
			v-col( cols="12" sm="8" md="5" )
				v-form(ref="skuform" class="d-flex")
					v-text-field(
						v-model="articulocodigo"
						label="idu_ArticuloCodigo"
						class="mr-5"
						type="number"
						:rules="required")
					v-btn(color="blue-500" dark class="text-none ml-5" @click="fetchHistory" :loading="loading") Buscar
				
				v-row(v-if="notFound" justify="center" align="center")
					v-col( cols="12" class="mt-0 pt-0")
						v-alert( type="error") No se encontraron resultados.
		v-row(justify="center" align="center")
			v-col( cols="9" sm="10" lg="9")
				div(id="sku"   v-if="showGraph" style="width: 100%;height: 500px;")
			v-col( cols="12" sm="2" lg="3" v-if="showGraph")
				v-card
					v-card-text
						p <span class="font-weight-black">des_TipoCompra:  </span>{{des_TipoCompra }}
						p  <span class="font-weight-black">idu_articulocodigo: </span>{{articulocodigo}}
						p  <span class="font-weight-black">idu_areacodigo: </span>{{idu_areacodigo}}
						p <span class="font-weight-black">cantidad a pedir:  </span> {{qty_to_request}}
						p <span class="font-weight-black">stock de seguridad:  </span>{{stock_de_seguridad }}
						

		v-row(justify="center" align="center" class="hidden-sm-and-down")
			v-col( cols="12" sm="10" )
				div(id="pieCLN" style="width: 100%; height: 400px;")
			v-col( cols="12" sm="10" )
				div(id="pieGDL" style="width: 100%; height: 400px;")
			v-col( cols="12" sm="10" )
				div(id="pieCXTP" style="width: 100%; height: 400px;")
			v-col( cols="12" sm="10" )
					div(id="pieTOLC" style="width: 100%; height: 400px;")
		v-row(justify="center" align="center" class="hidden-md-and-up")
			v-col( cols="12" )
				v-card(v-for="(item,key) of cards" :key="key")
					v-card-title
						label(class="font-weight-black") {{item.name}}
					v-card-text
						p <span class="font-weight-black subtitle-2 red--text">Productos con alerta:  </span>  {{item.conalerta_cantidad+' - '+item.conalerta_porcentaje}} 
						p( v-for="(tipo, key) of item.conalerta_tipo ")  <span class="font-weight-black">{{tipo.name}} </span>{{tipo.qty+' - '+tipo.percent}} 

						v-divider(class="my-5")

						p  <span class="font-weight-black subtitle-2">Productos sin alerta: </span>  {{item.sinalerta_cantida+' - '+item.sinalerta_porcentaje}} 
						p( v-for="(tipo, key) of item.sinalerta_tipo ")  <span class="font-weight-black">{{tipo.name}} </span>{{tipo.qty+' - '+tipo.percent}} 
						
			//- v-col( cols="12" sm="10" )
				//- div(id="pieCLNMobile" style="width: 100%; height: 400px;")
			//- v-col( cols="12" sm="10" )
			//- 	div(id="pieGDLMobile" style="width: 100%; height: 400px;")
			//- v-col( cols="12" sm="10" )
			//- 	div(id="pieCXTPMobile" style="width: 100%; height: 400px;")
			//- v-col( cols="12" sm="10" )
			//- 	div(id="pieTOLCMobile" style="width: 100%; height: 400px;")
		v-row
			v-col( cols="12" sm="12" class="py-0")
				v-card
					v-card-title
						v-text-field(
							@input="filterSearch"
							append-icon="mdi-magnify"
							label="Buscar por idu_ArticuloCodigo"
							single-line
							class="ml-4"
							max-width="300")
						.flex-grow-1

					v-data-table(
						:headers="headers"
						:items="transactions"
						
						:search="search"
						
						:items-per-page="50"
						:loading="loadingData"
						class="elevation-2")

</template>
<script>
	var echarts = require('echarts');
	import StockService from '../../services/StockService';
	import Rules from '../../plugins/Rules.js';
	
	const stockService  = new StockService();
	const rules = new Rules();

	const pie = {
					title : {
						text: '',
						padding: 5,
						subtext: '',
						x:'left'
					},
					tooltip : {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'horizontal',
						center: 'center',
						top: 20,
						data: [
							]
					},
					series : [
						{
							name: 'Alertas',
							type: 'pie',
							radius: '65%',
							center: ['50%', '50%'],
							label: {
								show: true,
								position: 'left'
							},
							data:[],
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					],
					color:["#003960","#00548C"]
				}
	export default {
		name: 'DashboardPim',

		data() {
			return {
				loading: false,
				notFound: false,
				showGraph : false,
				showPies : false,

				headers			: [],
				transactions	: [],
				search			: "",
				loadingData 	: true,
				item_header : {
						text: '', 
						value: '', 
						type: "text",
						filterable: false,
						align: "center",
						sortable: false 
				},
				filters: {
					search: '',
					idu_ArticuloCodigo: '',
				},
				
				stock_de_seguridad:0,
				idu_areacodigo:0,
				qty_to_request: 0,
				des_TipoCompra:'',

				cards : [
					// {
					// 	name: 'CLN',
					// 	conalerta_cantidad:0,
					// 	conalerta_porcentaje:0,
					// 	conalerta_tipo:[],
					// 	sinalerta_cantida:0,
					// 	sinalerta_porcentaje: 0,
					// 	sinalerta_tipo: [],
					// }
				],
				

				articulocodigo : "100104",
				items : [
					{ text:"ropa", 	value:2},
					{ text:"muebles", value:3},
				],
				area: 2,

				// 
				graph_sku: {
					title: {
						text: 'Histórico CLN',
						subtext: ''
					},
					legend: {
						top: 70,
						data: ['Disponibilidad', 'Stock de seguridad']
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					toolbox: {
						show: true,
						feature: {
							dataZoom: {
								title:'',
								yAxisIndex: false
							},
							saveAsImage: {
								title:'C',
								pixelRatio: 2
							}
							
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} '
						},
						axisPointer: {
							snap: true
						}
					},
					series: [
						{
							name: 'Disponibilidad',
							type: 'line',
							smooth: true,
							color: 'green',
							data: [],
						},
						{	
							name: 'Stock de seguridad',
							data: [['2019-11-24', 50], ['2020-04-04', 50]],
							color: 'red',
							type: 'line'
						}
					]
				},

				required : rules.required,
			}
		},
		components:{
		},
		mounted(){
			this.graphPie();
			// this.fetchTransactions();
		},
		methods:{
			fetchTransactions: function(){
			
				stockService.all()
				.then((response)=>{
					this.transactions = response.data.data;
					this.loadingData = false;
					let headers = response.data.columns;

					headers.map( item=>{
						let column 		= Object.assign({}, this.item_header);
						column.text 	= item;
						column.value 	= item;
						
						if (item=="idu_ArticuloCodigo")
							column.filterable =true;

						this.headers.push(column);
					})

				})
				.catch(error=>{
					console.log('error')

				});
			},
			fetchHistory: function(){
				if(!this.$refs.skuform.validate())
					return;

				this.loading  = true;
				this.notFound = false;
				this.showGraph = false;

				let formData = new FormData();
				formData.append('idu_articulocodigo', this.articulocodigo);
				formData.append('idu_AreaCodigo', 2);
				
				stockService.search(formData)
				.then(response=>{
					
					this.loading = false;
					if(response.data.date.length==0){
						this.notFound = true;
						return;
					}
					this.showGraph = true;

					let dates = response.data.date;
					let info = response.data.info[0];

					this.graph_sku.title.subtext = this.articulocodigo;

					if(response.data.info.length>0){
						
						let stock =  info.stock_de_seguridad_nivel_sku;
						this.des_TipoCompra = info.des_TipoCompra;
						
						// this.idu_ArticuloCodigo = info.idu_ArticuloCodigo;
						this.idu_areacodigo 	= 2;
						this.stock_de_seguridad = stock;
						this.qty_to_request = info.cantidad_a_pedir_por_talla;

						let line = [[dates[0], stock], [ dates[dates.length-1], stock] ]
						this.graph_sku.series[1].data 		= line;
					}

					this.graph_sku.xAxis.data 		= dates;
					this.graph_sku.series[0].data 	= response.data.disponibles;

					

					setTimeout(()=>{
						var graph_sku = echarts.init(document.getElementById('sku'));
						graph_sku.setOption(this.graph_sku);

					},500)

				
				})
				.catch(error=>{
					console.log("error")
					this.loading = false
					console.log(error);
					this.notFound = false;
				})
			},
			labels_of_pie: function(values){
				return {
					formatter: values.join('\n'),
					backgroundColor: '#eee',
					borderColor: '#777',
					borderWidth: 1,
					borderRadius: 4,
					rich: {
						title: {
							color: '#eee',
							align: 'center'
						},
						abg: {
							backgroundColor: '#333',
							width: '100%',
							align: 'right',
							height: 25,
							borderRadius: [4, 4, 0, 0]
						},
						hr: {
							borderColor: '#777',
							width: '100%',
							borderWidth: 0.5,
							height: 0
						},

						des_TipoCompraH: {
							color: '#333',
							width: 180,
							height: 24,
							align: 'center'
						},
						percentageH:{
							color: '#333',
							width: 40,
							height: 20,
							padding: [0, 0, 0,0],
							align: 'left'

						},
						qtyH: {
							color: '#333',
							width: 40,
							align: 'left',
							padding: [0, 0, 0,5],
						},
						value: {
							width: 180,
							height: 24,
							padding: [0, 0, 0,5],
							align: 'left'
						},
						percentage: {
							width: 40,
							height: 20,
							align: 'center',
							padding: [0, 0, 0,0],
						},
						rate: {
							width: 40,
							align: 'right',
							padding: [0, 5, 0,0],
						},
						
					}
				}

			},
			graphPie: function(){

				stockService.pie()
				.then( async response=>{

					this.cards.push()

					let data = response.data;
					console.log(data.CLN_dict);

					this.cards.push(data.CLN_dict);
					this.cards.push(data.GDL_dict);
					this.cards.push(data.CXTP_dict);
					this.cards.push(data.TOLC_dict);

					let labels_of_pie1 = this.labels_of_pie(data.CLNTrue);
					let labels_of_pie2 = this.labels_of_pie(data.CLNFalse);

					let labels_of_pie3 = this.labels_of_pie(data.GDLTrue);
					let labels_of_pie4 = this.labels_of_pie(data.GDLFalse);

					let labels_of_pie5 = this.labels_of_pie(data.CXTPTrue);
					let labels_of_pie6 = this.labels_of_pie(data.CXTPFalse);

					let labels_of_pie7 = this.labels_of_pie(data.TOLCTrue);
					let labels_of_pie8 = this.labels_of_pie(data.TOLCTrue);

					let valuesCLN = [
						{
							'name':'CLN con alerta',
							'value': data.CLN[0]['total'],
							"label": labels_of_pie1
						},
						{
							'name':'CLN sin alerta',
							'value':data.CLN[1]['total'],
							'label': labels_of_pie2
						},
					];
					
					let valuesGDL = [
						{
							'name':'GDL con alerta',
							'value':data.GDL[0]['total'],
							'label':  labels_of_pie3
						},
						{
							'name':'GDL sin alerta',
							'value':data.GDL[1]['total'],
							'label':  labels_of_pie4
						}
					];
					let valuesCXTP = [
						{
							'name':'CXTP con alerta',
							'value':data.CXTP[0]['total'],
							'label': labels_of_pie5
						},
						{
							'name':'CXTP sin alerta',
							'value':data.CXTP[1]['total'],
							'label': labels_of_pie6
						},
					];
					let valuesTOLC = [
						{
							'name':'TOLC con alerta',
							'value':data.TOLC[0]['total'],
							'label': labels_of_pie7
						},
						{
							'name':'TOLC sin alerta',
							'value':data.TOLC[1]['total'],
							'label': labels_of_pie8
						},
					];

					await this.pie1(valuesCLN);
					await this.pie2(valuesGDL);
					await this.pie3(valuesCXTP);
					await this.pie4(valuesTOLC);

					// pie.title.subtext = 'Alertas '+this.formatNumber(response.data.total) +' de '+this.formatNumber(response.data.totalClientes)

				})
				.catch(error=>{
					console.log("Error: No connect with graph ")
					console.log(error)
				})

			},
			pie1:  function(valuesCLN){

				return new Promise( (resolve, reject)=> { 
					let CLN = pie;
					let CLNMobile = pie;
						
					CLN.series[0].data = valuesCLN;
					CLN.legend.data = ['CLN con alerta','CLN sin alerta'];
					var pieCLN = echarts.init(document.getElementById('pieCLN'));
					pieCLN.setOption(CLN);
					
					// delete valuesCLN[0]['label'];
					// delete valuesCLN[1]['label'];
					// CLNMobile.series[0].data = valuesCLN;
					// CLNMobile.legend.data = ['CLN con alerta','CLN sin alerta'];
					// var pieCLNMobile = echarts.init(document.getElementById('pieCLNMobile'));
					// pieCLNMobile.setOption(CLNMobile);
					resolve();

				} );

			},
			pie2:  function(valuesGDL){
				return new Promise((resolve, reject)=> { 

					let GDL	= pie;
					let GDLMobile = pie;

					GDL.series[0].data = valuesGDL;
					GDL.legend.data = ['GDL con alerta','GDL sin alerta'];
					var pieGDL = echarts.init(document.getElementById('pieGDL'));
					pieGDL.setOption(GDL);

					// delete valuesGDL[0]['label'];
					// delete valuesGDL[1]['label'];
					// GDLMobile.series[0].data = valuesGDL;
					// GDLMobile.legend.data = ['GDL con alerta','GDL sin alerta'];
					// var pieGDLMobile = echarts.init(document.getElementById('pieGDLMobile'));
					// pieGDLMobile.setOption(pieGDLMobile);

					resolve();
				});

			},
			pie3:  function(valuesCXTP){
				return new Promise( (resolve, reject)=> { 
					let CXTP = pie;
					let CXTPMobile 	= pie;

					CXTP.series[0].data = valuesCXTP;
					CXTP.legend.data = ['CXTP con alerta','CXTP sin alerta'];
					var pieCXTP = echarts.init(document.getElementById('pieCXTP'));
					pieCXTP.setOption(CXTP);

					// delete valuesCXTP[0]['label'];
					// delete valuesCXTP[1]['label'];
					// CXTPMobile.series[0].data = valuesCXTP;
					// CXTPMobile.legend.data = ['CXTP con alerta','CXTP sin alerta'];
					// var pieCXTPMobile = echarts.init(document.getElementById('pieCXTPMobile'));
					// pieCXTPMobile.setOption(pieCXTPMobile);
					resolve();
				});

			},
			pie4:   function(valuesTOLC){
				return new Promise( (resolve, reject)=> { 
					let TOLC = pie;
					let TOLCMobile	= pie;

					TOLC.series[0].data = valuesTOLC;
					TOLC.legend.data = ['TOLC con alerta','TOLC sin alerta'];
					var pieTOLC = echarts.init(document.getElementById('pieTOLC'));
					pieTOLC.setOption(TOLC);

					// delete valuesTOLC[0]['label'];
					// delete valuesTOLC[1]['label'];
					// TOLCMobile.series[0].data = valuesTOLC;
					// TOLCMobile.legend.data = ['TOLC con alerta','TOLC sin alerta'];
					// var pieTOLCMobile = echarts.init(document.getElementById('pieTOLCMobile'));
					// pieTOLCMobile.setOption(pieTOLCMobile);
					resolve();
				});
				

			},
			//filter TABLE
			customFilter(value, search, item) {
				let items = Object.keys(item);
				return items.filter(row => filter(row["idu_ArticuloCodigo"], search));
			},
			filterSearch(val) {
				// this.filters = this.$MultiFilters.updateFilters(this.filters, {idu_ArticuloCodigo: val});
				setTimeout(()=>{
					this.search = val;
				},100)
				
			},
		},
		
	
	}
</script>
