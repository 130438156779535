import BackendAuthService from "./BackendAuthService";

export default class Stock extends BackendAuthService {

	constructor() {
		super();
	}

	all(){
		return this.httpCall('stock/all',{
			method: 'POST',
		});
	}

	search(data){
		return this.httpCall('stock/search',{
			method: 'POST',
			data
		});
	}

	pie(){
		return this.httpCall('stock/pie',{
			method: 'POST',
		});
	}



}

