export default class Rules {

	constructor() {
		this._required = [
			v => !!v || 'Se require este campo',
		]

		this._limitDigit = [
			v => !!v || 'Se require este campo',
			v => /^\d+$/.test(v) || 'Solo numeros',
			v => v.length == 5 || 'Deben ser 5 Digitos',
		]
	}

	//get
	get required(){
		return  this._required;
	}
}